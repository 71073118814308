import React from "react";

import "./carousel.css"

import firstImage from "../../assets/home/students-walking-homebanner.jpg"

const Carousel = () => (
    <div className="justify-content-center ">
        <img src={firstImage} className="img-fluid justify-content-center carouselstm" height={600} alt="..."/>
    </div>
)

export default Carousel
