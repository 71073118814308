import React from 'react';
import './CovidBar.css';

import homelearningpolicy from "../../assets/home/COVID-19_Home_Learning_Policy.pdf";
import studenthomelearningpolicy from "../../assets/home/Google Meet Guide - For Students.pdf";

export default class OpenDayBar extends React.Component {
    render(){
        return(
            <div className="sticky-top top-bar container-fluid">
                <div className="d-flex justify-content-center top-bar-h1">
                    <h1>OPEN MORNING</h1>
                </div>

                <div className="d-flex justify-content-center top-bar-h2">

                        <h2>Thursday 29th August 2024 9:30AM - 11:00AM</h2>
                </div>

            </div>
        )
    }
}
