import React from "react";
import { Link } from "gatsby";
import "./schoolYears.css";
import primaryYearImage from "../../assets/home/school-primary-years.jpg";
import secondaryYearImage from "../../assets/home/secondary-year.jpg";
import principal from "../../assets/home/meet-the-principal-sr-margaret.jpg";
import howtoEnrol from "../../assets/home/how-to-enrol.jpg";

const school_years_data = [
  {
    title: "Primary Years",
    image_path: primaryYearImage,
    url: "education/primaryyear",
  },
  {
    title: "Secondary Years",
    image_path: secondaryYearImage,
    url: "education/secondaryyear",
  },
  {
    title: "Meet The Principal",
    image_path: principal,
    url: "about/fromtheprincipal",
  },
  {
    title: "How To Enrol",
    image_path: howtoEnrol,
    url: "enrolment",
  },
];
const SchoolYears = () => {
  return (
    <div className="container-fluid school-year-wrapper">
      <div className="container">
        <div className="row">
          {school_years_data.map((school) => (
            <div
              className="col-md-3 col-sm-6 col-xs-6 col-xxs-12"
              key={school.url}
            >
              <Link to={`/${school.url}`}>
                <div
                  className="school-year-box"
                  style={{ background: `url("${school.image_path}")` }}
                >
                  <div className="caption-box">
                    <div className="caption">
                      <h4>{school.title}</h4>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SchoolYears;
