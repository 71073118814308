import React from 'react';
import './CovidBar.css';

import homelearningpolicy from "../../assets/home/COVID-19_Home_Learning_Policy.pdf";
import studenthomelearningpolicy from "../../assets/home/Google Meet Guide - For Students.pdf";

export default class CovidBar extends React.Component {
    render(){
        return(
            <div className="sticky-top top-bar container-fluid">
                <div className="d-flex justify-content-lg-end justify-content-center ">
                    <h1>Covid 19 Information</h1>
                </div>

                <div className="d-flex justify-content-lg-end justify-content-center ">
                    <a
                        href={homelearningpolicy}
                    >
                        <h2>Home Learning Policy</h2>
                    </a>
                </div>
                <div className="d-flex justify-content-lg-end justify-content-center ">
                    <a
                        href={studenthomelearningpolicy}
                    >
                        <h2> Home Learning Guide for Students</h2>
                    </a>
                </div>
            </div>
        )
    }
}
